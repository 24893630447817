<template>
  <div class="about_box">
    <div class="head_nav">
      <div class="title">- 关于我们 -</div>
      <div class="slogan">绿能派 致力于公益的节能大使</div>
    </div>
    <div class="content_nav">
      <div class="video_box">
        <video id="video" type="video/mp4" class="video_play" x-webkit-airplay="true" playsinline="true" controls x5-video-player-type="h5" preload="auto" src="https://sys.huwei.xin:7443/huwei-website/video/1.mp4" />
      </div>
      <div class="company_text">
        <div class="app_explain_bg">HUWEI</div>
        <span>杭州互为综合能源服务有限公司</span>
      </div>
    </div>
    <div class="green_nav">
      <div class="title">绿能派</div>
      <div class="content_text">
        “绿能派”由杭州互为综合能源服务有限公司创建，始终秉承“源于社会回馈社会”的社会责任理念，大力倡导和发展绿色低碳事业，积极发起并参与教育、环保、慈善等社会公益项目。<br/><br/>
        为实现“每人每天省一度电”的愿景，我们潜心研发一套双管齐下的节能模式，运用物联网、云计算、大数据、移动互联网等技术，构建酒店行业能源物联网平台，为酒店提供全方位服务；推广“绿能派”移动应用，建立绿能积分体系，为参与绿能派成员积累碳资产。
      </div>
    </div>
    <div class="information_box">
      <div class="label_text">
        <div class="app_explain_bg">INFORMATION</div>
        <span>互为资讯</span>
      </div>
      <div class="list_box">
        <div class="list" :class="index == 0? 'new_box' : '' " @click="go(index)" v-for="(item, index) in informationList" :key="item.title">
          <div class="new" v-if="index == 0">最新</div>
          <img class="img" :src="('https://sys.huwei.xin:7443/huwei-website/'+ item.img)" alt="">
          <div class="list_content">
            <div class="title">{{ item.title }}</div>
            <div class="abstract">{{ item.abstract }}</div>
            <div class="bottom">
              <div>绿能派 | <span class="mark">原创</span></div>
              <div><span><img src="https://sys.huwei.xin:7443/huwei-website/icon/time_icon.png" alt=""></span>{{ item.date }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="more" @click="_more">查看更多</div> -->
    </div>
    <div class="partner_nav">
      <div class="partner_title">
        <div class="app_explain_bg">PARTNER</div>
        <span>部分合作伙伴</span>
      </div>
      <div class="partner_box">
        <div class="img" v-for="item in partnerList" :key="item.name">
          <img :src="('https://sys.huwei.xin:7443/huwei-website/partner/'+ item.logo)" :alt="item.name" >
        </div>
      </div>
    </div>
    <div id="allmap">
      <img
        class="map"
        referrerpolicy="no-referrer"
        src="../assets/img/map_bg.png"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, getCurrentInstance, onMounted   } from "vue";
import { useRouter } from 'vue-router'
export default {
  name: "greenEnergy",
  components: {
  },
  setup() {
    const dom = getCurrentInstance()
    const state = computed(() => useStore().state.client);
    const router = useRouter()
    const informationList = ref([
      {
        img: 'information-1.png',
        title: '首秀惊艳，未来可期 | 首届数贸会在杭州圆满落幕，互为“绿能专家”收获颇丰！',
        abstract: '12月14日，首届全球数字贸易博览会在杭州国际博览中心圆满落幕，各类展览和论坛顺利召开，“绿能专家”首秀表现惊艳，不仅获得官方认可，还收获一众粉丝青睐。',
        date: '2022-12-20  15:30'
      },
      {
        img: 'information-2.png',
        title: '行业动态 | 2022年浙江省饭店业协会年度风云大会',
        abstract: '浙江省饭店业协会8月30日至31日在杭州黄龙饭店召开“2022年浙江省饭店风云大会暨浙江省饭店业协会第四届二次理事会扩大会议”，来自全省各级文化和旅游行政部门、专家学者、行业协会、饭店会员单位、合作商代表等共同参加大会。',
        date: '2022-09-01  16:01'
      },
    ])
    const partnerList = ref([
      {
        name: '万豪国际集团',
        logo: 'wh.png',
      },
      {
        name: '海外海集团',
        logo: 'hwh.png',
        href: 'https://www.haiwaihai.com/',
      },
      {
        name: '两淮控股集团',
        logo: 'lhkg.png',
      },
      {
        name: '首旅如家',
        logo: 'rj.png',
      },
      {
        name: '国家电网',
        logo: 'gjdw.png',
      },
      {
        name: '中国饭店协会',
        logo: 'cn_hotel_association.png',
      },
      {
        name: '浙江饭店业协会',
        logo: 'zjfdxh.png',
      },
      {
        name: '格雷斯精选酒店',
        logo: 'gls.png',
      },
    ])
    onMounted(() => {
      // 初始化地图
      // loadMapScript(); // 加载百度地图资源
    });
    const init = () => {
      let Bmap = window.BMap; // 注意要带window，不然会报错（注意官方api,会有改动，之前是Bmap,后面3.0版本改为了BMap,最好查文档或者打印一下window）
      var map = new Bmap.Map("allmap"); 
      var point = new Bmap.Point(120.12350 , 30.29607);
      var addressTcon = new Bmap.Icon('https://sys.huwei.xin:7443/huwei-website/icon/location_icon.png', new Bmap.Size(64,64))
      var marker = new Bmap.Marker(point, {icon: addressTcon})
      var label = new Bmap.Label('1', {
        offset : new Bmap.Size(5, 4)
      }); 
      label.setStyle({
        background:'none',color:'#fff',border:'none'
      });
      marker.setLabel(label);
      // map.addOverlay(label)
      map.addOverlay(marker)
      map.centerAndZoom(
        new Bmap.Point(120.12350 , 30.29627),
        20
      ); // 初始化地图,设置中心点坐标和地图级别
      map.setCurrentCity("");
      map.enableScrollWheelZoom(true);
    };
    const loadMapScript = () => {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.className = "loadmap"; // 给script一个类名
      script.src =
        "https://api.map.baidu.com/getscript?v=3.0&ak=So41LIbBgsBFgzR3x2sKaIX1NGqxHpx8";
        // 此处需要注意：申请ak时，一定要应用类别一定要选浏览器端，不能选服务端，不然地图会报ak无效
      script.onload = () => {
        init();
      };
      let loadmap = document.getElementsByClassName("loadmap");
      if (loadmap) {
        // 每次append script之前判断一下，避免重复添加script资源标签
        for (var i = 0; i < loadmap.length; i++) {
          document.body.removeChild(loadmap[i]);
        }
      }
 
      document.body.appendChild(script);
    };
    const go = (i) => {
      // console.log(i);
      if(i == 0) {
        router.push('/information')
      }else if(i ==1) {
        router.push('/information_2')
      }
    }
    return {
      dom,
      state,
      informationList,
      partnerList,
      init,
      loadMapScript,
      go
    }
  }
}
</script>
<style lang="scss" scoped>
#allmap {
  // 注意给dom宽高，不然地图不出来
  width: 100%;
  // max-width: inherit;
  // background: url('../assets/img/map_bg.png') no-repeat 100% 100%;
  .map{
    width: 100%;
  }
}
.about_box {
  .head_nav {
    background: url('https://sys.huwei.xin:7443/huwei-website/about_bg.png') no-repeat 100% 100%;
    background-size: cover;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    .title {
      font-size: 22px;
      line-height: 48px;
    }
    .slogan {
      font-size: 40px;
      line-height: 48px;
    }
  }
  .content_nav {
    height: 830px;
    box-sizing: border-box;
    padding: 100px 523px 80px;
    .video_box {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .video_play {
      width: 875px;
      height: 492px;
      background: #000000;
      border-radius: 10px;
    }
    .company_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 80px 0 38px;
      box-sizing: border-box;
      .app_explain_bg {
        position: absolute;
        font-size: 150px;
        font-weight: bold;
        color: #FBFBFB;
        line-height: 120px;
      }
      span {
        font-size: 36px;
        font-weight: 800;
        color: #3D3F55;
        line-height: 36px;
        text-align: center;
        position: relative;
      }
    }
  }
  .green_nav {
    height: 319px;
    background: #F7F7F7;
    box-sizing: border-box;
    padding: 60px 359px;
    .title {
      font-size: 24px;
      color: #474747;
      line-height: 33px;
      text-align: center;
      padding-bottom: 16px;
    }
    .content_text {
      font-size: 18px;
      color: #474747;
      line-height: 25px;
      font-weight: 100;
    }
  }
  .information_box {
    height: 1147px;
    box-sizing: border-box;
    padding: 184px 316px;
    .label_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 80px 0 38px;
      box-sizing: border-box;
      .app_explain_bg {
        position: absolute;
        font-size: 150px;
        font-weight: bold;
        color: #FBFBFB;
        line-height: 120px;
      }
      span {
        position: relative;
        font-size: 36px;
        font-weight: 800;
        color: #3D3F55;
        line-height: 36px;
        text-align: center;
      }
    }
    .list_box {
      padding-top: 125px;
      .list {
        display: flex;
        padding: 40px;
        position: relative;
        cursor: pointer;
        .new {
          position: absolute;
          background: #000000;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 300;
          color: #FFFFFF;
          line-height: 20px;
          padding: 5px 20px;
          top: 50px;
          left: 50px;
          opacity: 0.53;
        }
        .img {
          width: 334px;
          height: 223px;
        }
        .list_content {
          padding-left: 30px;
          display: grid;
          grid-template-rows: auto auto 25px;
          .title {
            font-size: 24px;
            color: #474747;
            line-height: 33px;
          }
          .abstract {
            font-size: 18px;
            font-weight: 100;
            color: #474747;
            line-height: 25px;
          }
          .bottom {
            font-size: 18px;
            font-weight: 300;
            color: #474747;
            line-height: 25px;
            display: flex;
            justify-content: space-between;
            .mark {
              color: #1667FF;
            }
            img {
              height: 25px;
              width: 25px;
              position: relative;
              top: 5px;
              right: 22px;
            }
          }
        }
      }
      .list:hover {
        box-shadow: 0px 0px 20px 3px #ebedf1;
        transition: all ease-in-out .3s;
      }
      .new_box {
        background: #F7F7F7;
      }
    }
    .more {
      width: 289px;
      height: 48px;
      border: 1px solid #1667FF;
      font-size: 18px;
      color: #4F8CFF;
      line-height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 50%;
      margin-top: 82px;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
    .more:hover {
      // box-shadow: 0px 0px 20px 1px #8bb3ff;
      // text-shadow: 0px 0px 18px #8bb3ff;
      // transition: all ease-in-out .3s;
      background: #1563f6;
      color: #ffffff;
    } 
  }
  .partner_nav {
    height: 706px;
    padding: 202px 350px 150px;
    box-sizing: border-box;
    .partner_title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 80px;
      box-sizing: border-box;
      .app_explain_bg {
        position: absolute;
        font-size: 150px;
        font-weight: bold;
        color: #FBFBFB;
        line-height: 120px;
      }
      span {
        position: relative;
        font-size: 36px;
        font-weight: 800;
        color: #3D3F55;
        line-height: 36px;
        text-align: center;
      }
    }
    .partner_box {
      display: grid;
      grid-gap: 80px 140px;
      grid-template-columns: repeat(4, 1fr);
      .img {
        width: 200px;
        height: 79px;
        margin-top: -20px;
      }
    }
  }

}
</style>
